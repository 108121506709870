import React from 'react';
import bg from '../assets/bg.png';
import { PrimaryButton, SecondaryButton } from '.';
import Lottie from './lottie';

interface IHero {
  feesEarned: string;
}

const Hero: React.FC<IHero> = ({ feesEarned }) => {
  return (
    <section
      style={{ backgroundImage: `url(${bg})`, backgroundRepeat: 'no-repeat' }}
      className={`px-5 pb-10 md:py-20  bg-local text-center md:text-left object-fill bg-secondary text-white flex`}>
      <div className='container mx-auto flex'>
        <div className='flex-1'>
          <h1 className='text-2xl sm:text-4xl sm:text font-bold border-primary md:border-l-4 md:pl-4 mb-4'>
            Frictionless Yield Generation. Hold and Earn.
          </h1>
          <p className='sm:text-lg font-light'>
            RFI works by applying a 1% fee to each transaction and instantly splitting that fee
            among all holders of the token.
          </p>
          <br />
          <p className='sm:text-lg font-light'>
            Holders do not need to stake or wait for fees to be delivered. Fees are awarded by the
            smart contract and are <b>immediately reflected in the holders balance</b>.
          </p>
          <div className='mt-10'>
            <div className='hero-buttons'>
              <a href='https://t.me/reflect_finance' target='_blank' rel='noopener noreferrer'>
                <SecondaryButton text='Telegram' />
              </a>
            </div>
            <div className='hero-buttons'>
              <a
                href='https://app.uniswap.org/#/swap?outputCurrency=0xa1afffe3f4d611d252010e3eaf6f4d77088b0cd7'
                target='_blank'
                rel='noopener noreferrer'>
                <PrimaryButton text='Uniswap' />
              </a>
            </div>
          </div>
        </div>
        <div className='hidden lg:flex flex-1 items-center justify-end'>
          <Lottie />
        </div>
      </div>
    </section>
  );
};
export { Hero };
