import React, { useEffect, useState } from 'react';
import { Section, Hero } from '../components';
import ROI from '../assets/enhanced_roi.png';
import DualYield from '../assets/dual_yield.png';
import Decentralized from '../assets/decentralized.png';
// import { PrimaryButton } from '../components/button';

import axios from 'axios';
import { apiUrl } from '../utils/constants';

const Landing: React.FC = () => {
  const [currentPrice, setCurrentPrice] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(apiUrl);
      setCurrentPrice(result.data.tickers[0].converted_last['usd']);
    };
    fetchData();
  }, []);

  return (
    <main className='flex flex-col'>
      <Hero feesEarned={currentPrice} />
      <Section
        image={Decentralized}
        title='True Decentralization'
        paragraph='There is no team or central party that awards fees with RFI.  There is no interface needed to claim the fees.  No action needs to be taken on the part of the holder other than to hold RFI in a wallet they control.  With RFI, there are no vaults that could be hacked and drained or treasury funds that could be mis-managed.  There is only the free market.'>
        <p className='font-light sm:text-lg mt-5'>
          <b>
            The RFI smart contract is complete at launch. There was no ICO, no pre-sale, and no
            fundraising of any kind. There are no more features to add. There is no individual or
            team to be relied upon to give RFI any value.
          </b>
        </p>
      </Section>
      <Section
        image={DualYield}
        bgcolor='secondary'
        title='Dual Yield Capability'
        paragraph='In most DeFi applications, users must stake or park their tokens in a contract to earn a yield.  RFI holders can use their tokens in third party lending, yield farming, or any other smart contract in addition to earning yield from the transaction fees.'>
        <p className='font-light sm:text-lg mt-5'>
          To facilitate this, the RFI smart contract exposes some new methods that allow staking
          contracts to easily determine the fees earned by each holder for any period of time even
          when funds are pooled together.{' '}
          <b>This is a huge leap that enables direct staking of RFI and double yield generation.</b>
        </p>
      </Section>
      <Section
        image={ROI}
        title='Enhanced ROI'
        paragraph='Innovations in the reflect.finance smart contract allow certain addresses, like the Uniswap pool or exchange wallets, to be blocked from earning fees.'>
        <p className='font-light sm:text-lg mt-5'>
          Because of this, <b>100% of the fees generated go to holders of the token</b>. The
          percentage of fees you earn is calculated by the percentage of RFI that you own among
          holders. This generates a much higher yield than would be possible otherwise.
        </p>
      </Section>
    </main>
  );
};
export default Landing;
